
#h2Goals{
    margin: 5%;
}
#goalsBox{
    font-family: 'Gill Sans', sans-serif;
    width: 90%;
    height: 160px;
    margin-left: 5%;
    border: none;
    border-radius: 4%;
    background-color: rgb(232, 252, 251);
    overflow: scroll;
    overflow-x: hidden;
}

#goalAdd{
    margin-left: 5%;
    margin-top: 1%;
}
#goalsInput{
    height: 30px;
    width: 180px;
    border-radius: 4px;
    border-width: 1px;
}
#achieveDate{
    height: 30px;
    width: 110px;
    border-radius: 4px;
    border-width: 1px;
}

#goalsButton{
    height: 30px;
    width: 110px;
    border-radius: 5px;
    color: black;
    background-color: rgb(73, 230, 125);
}