/*.Day {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(10, 100px);
    /*background-color: #41adc0;*
    background-image: url('../pictures/white_gold.avif');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
}

.Day header {
    display: flex;
    font-size: 25px;
    font-family: "Courier", monospace;
    /*background-color: rgb(113, 226, 169);*
    background-color: #fce834a4;
    grid-column: 1/13;
    grid-row: 1;
}

#Day2Day{
    align-items: center;
    margin: auto;
}

.Day #day{
    display: flex;
    justify-content: center;
    grid-column: 1/13;
    grid-row: 2/6;
    margin-left: -10%;
}

.Day #day button{
    display: block;
    padding: 0px;
    margin-top: 5%;
    font-family: "Trebuchet MS", sans-serif;
    border: none;
    border-radius: 20px;
    cursor: pointer;
}
.dailyTasks {
    background-color: #AAF1FB;
    width: 300px;
    height: 100px;
    font-size: 25px;
}

.wordle {
    background-color: #FCB8FD;
    width: 300px;
    height: 100px;
    font-size: 25px;
}

.Day article{
    background-color: #F9E499;
    grid-column: 4/10;
    grid-row: 6/10;
    justify-content: center;
    width: 950px;
    border-radius: 20px;
}
*/

.Day {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(10, 100px);
    min-height: 100vh;
    background-image: url('../pictures/white_gold.avif');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    margin: 0;
    padding: 0;
}

.Day header {
    display: flex;
    font-size: 25px;
    font-family: "Courier", monospace;
    /*background-color: #fce834a4;*/
    grid-column: 1/13;
    grid-row: 1;
}

#Day2Day {
    align-items: center;
    margin: auto;
}

.Day #day {
    display: flex;
    justify-content: center;
    grid-column: 1/13;
    grid-row: 2/6;
    margin-left: -10%;
}

.Day #day button {
    display: block;
    padding: 0px;
    margin-top: 5%;
    margin-left: 5%;
    font-family: "Trebuchet MS", sans-serif;
    border: none;
    border-radius: 20px;
    cursor: pointer;
}

.Day .dailyTasks {
    background-color: #AAF1FB;
    width: 250px;
    height: 100px;
    font-size: 25px;
}

.Day .wordle {
    background-color: #FCB8FD;
    width: 250px;
    height: 100px;
    font-size: 25px;
}

.Day article {
    /*background-color: #F9E499;*/
    margin: auto;
    grid-column: 4/10;
    grid-row: 6/10;
    justify-content: center;
    width: 100%;
    max-width: 950px;
    border-radius: 20px;
    background-color: rgb(166, 247, 190);
}

@media only screen and (max-width: 768px) {
    .Day {
        grid-template-columns: 1fr;
    }

    .Day .dailyTasks {
        background-color: #AAF1FB;
        width: 150px;
        height: 80px;
        font-size: 25px;
    }
    
    .Day .wordle {
        background-color: #FCB8FD;
        width: 150px;
        height: 80px;
        font-size: 25px;
    }

    .Day article {
        grid-column: 1/-1;
        grid-row: 4/8;
    }
}
